/** @jsx jsx */
import { jsx, Text, Grid, Flex, } from 'theme-ui'
import React from "react"
import Link from '../components/Link'
import WavyLink from '../components/WavyLink'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <Text variant='h1' sx={{maxWidth: '20em', mb: 6}}>
          I’m Maxim, a 30-year-old product designer with a passion to turn good ideas into great-looking products. Focused on interaction design and mobile interfaces, but comfortable with web, too. <WavyLink href="/Maxim-Melnikov-designer-resume.pdf">My CV ↙︎</WavyLink>
        </Text>

        <Grid sx={{gridTemplateColumns: ['1fr', 'repeat(2, 2fr)', 'repeat(3, max-content)'], columnGap: [7, 8, 9, 10], rowGap: 5, maxWidth: [null, "44rem", null, "76rem"] }}>
          <Flex sx={{gridColumn: "1/-1", mx: -2, mb: 8, flexDirection: ["column", "row"]}}>
            <Link href='mailto:mxmmlnkv@gmail.com?subject=Hi there' sx={{variant: 'links.button', width: 'max-content', mb: [4, 0], mr: [0, 4]}}><span role='img' aria-hidden sx={{pr: 2}}>👋 </span>mxmmlnkv@gmail.com</Link>
            <Flex>
              <Link variant='links.button' sx={{mx: 1, color: "white", bg: "#2867b2"}} href='https://linkedin.com/in/maxmelnikov/'>
                <svg height="16" aria-hidden viewBox="0 0 16 16" version="1.1" width="16" role="img">
                  <path fillRule="nonzero" d="M14.8699981.03813001c.6529976 0 1.1823708.51331525 1.1823708 1.14614038V14.8919896c0 .633039-.5293732 1.1461404-1.1823708 1.1461404H1.23473958c-.65301907 0-1.18237072-.5131014-1.18237072-1.1461404V1.18427039c0-.63282513.52935165-1.14614038 1.18237072-1.14614038H14.8699981zM5.02481934 6.14664622H2.64685066v7.15433548h2.37796868V6.14664622zm5.83685726-.16788152c-1.20738209 0-1.7772731.63516568-2.10100721 1.11707484l-.04230053.06329047.00026731-1.01248379H6.34062515c.03126873.67131437 0 7.15433548 0 7.15433548h2.37794751V9.30569796c0-.21382135.01545442-.42743101.07824592-.58028152.1719039-.4272193.56317581-.86968131 1.22009432-.86968131.8604721 0 1.2046822.65607166 1.2046822 1.61784435v3.82740222h2.377757V9.19878728c0-2.19749075-1.1731595-3.22002258-2.7376755-3.22002258zM3.85155405 2.69777128c-.81343148 0-1.34527505.53412998-1.34527505 1.23614148 0 .68655708.51602926 1.23592977 1.31404866 1.23592977h.01545442c.82928813 0 1.34542324-.54937269 1.34542324-1.23592977-.01545442-.7020115-.51613511-1.23614148-1.32965127-1.23614148z"/>
                </svg>
                <span sx={{display: ["none", null, "unset"]}}>LinkedIn</span>
              </Link>
              <Link variant='links.button' sx={{mx: 1, color: "white", bg: "#1da1f2"}} href='https://twitter.com/bbsody'>
                <svg height="16" aria-hidden viewBox="0 0 16 16" version="1.1" width="16" role="img">
                  <path d="M15.762 3.45799967c-.5566667.24666666-1.1546667.41333333-1.7833333.48866666C14.62 3.56266633 15.112 2.953333 15.344 2.22799967c-.6.356-1.2646667.61466666-1.972.75333333-.5666667-.60266667-1.3733333-.98-2.2666667-.98C9.39066667 2.001333 8 3.39199967 8 5.10799967c0 .24266666.028.47866666.08.70666666-2.582-.13-4.86933333-1.36666666-6.40133333-3.24533333-.26666667.46-.42.99333333-.42 1.56133333 0 1.07733334.54866666 2.02866667 1.38133333 2.58533334-.50933333-.01666667-.988-.156-1.40666667-.38866667v.04c0 1.50466667 1.07 2.76 2.49133334 3.04533333-.26133334.07066667-.53533334.108-.818.108-.2 0-.39533334-.01866666-.58466667-.05466666.39533333 1.23333333 1.542 2.13200003 2.90133333 2.15600003-1.06333333.8333333-2.40266666 1.33-3.85733333 1.33-.25066667 0-.498-.0146667-.74133333-.0433334 1.37466666.882 3.00666666 1.3953334 4.76 1.3953334 5.71333333 0 8.83666663-4.73200003 8.83666663-8.83600003 0-.13333334-.0033333-.268-.0093333-.40133334.6066667-.43866666 1.1333333-.98466666 1.5486667-1.60666666l.0013333-.002z"/>
                </svg>
                <span sx={{display: ["none", null, "unset"]}}>Twitter</span>
              </Link>
              <Link variant='links.button' sx={{mx: 1, color: "white", bg: "#24292e"}} href='https://github.com/bbsody'>
                <svg height="16" aria-hidden viewBox="0 0 16 16" version="1.1" width="16" role="img"><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>
                <span sx={{display: ["none", null, "unset"]}}>Github</span>
              </Link>
              <Link variant='links.button' sx={{mx: 1, color: "white", bg: "#ea4c89"}} href='https://dribbble.com/bbsody'>
                <svg height="16" aria-hidden viewBox="0 0 16 16" version="1.1" width="16" role="img">
                  <path fillRule="evenodd" d="M8 0C3.583515 0 0 3.583515 0 8c0 4.4165 3.583515 8 8 8 4.4078 0 8-3.5835 8-8 0-4.416485-3.5922-8-8-8zm5.28415 3.687635c.95445 1.16269 1.52715 2.646415 1.5445 4.251615-.2256-.04335-2.4816-.50325-4.7549-.2169-.05205-.1128-.09545-.2343-.1475-.35575-.13885-.3297-.295-.6681-.4512-.98915 2.51625-1.02385 3.6616-2.498925 3.8091-2.689815zM8 1.180045c1.73535 0 3.3232.65076 4.5293 1.718005-.1215.173535-1.15405 1.553145-3.58355 2.4642C7.82645 3.305855 6.5857 1.62256 6.3948 1.362255c.5119-.121475 1.0499-.18221 1.6052-.18221zm-2.9067.64208c.1822.24295 1.39695 1.934925 2.5336 3.947925-3.19306.85035-6.013015.83302667-6.316705.83302667C1.75271 4.4859 3.18438 2.72451 5.0933 1.822125zM1.16269 8.0087v-.20825c.29501.00865 3.609545.05205 7.01951-.9718.1996.38175.3818.7722.55535 1.16265-.0868.02605-.18225.0521-.269.0781-3.52278 1.13665-5.396965 4.24295-5.55315 4.50325C1.8308 11.3666 1.16269 9.7614 1.16269 8.0087zM8 14.8373c-1.5792 0-3.036875-.53795-4.19089-1.44035.121475-.2516 1.50974-2.92405 5.36224-4.26895.01735-.0087.02605-.0087.0434-.01735.9631 2.4902 1.3536 4.5813 1.4577 5.18-.8243.35575-1.7267.54665-2.67245.54665zm3.8091-1.17135c-.0694-.4165-.43385-2.41215-1.32755-4.8677 2.1432-.3384 4.01735.21695 4.25165.29505-.295 1.9002-1.3883 3.5401-2.9241 4.57265z"/>
                </svg>
                <span sx={{display: ["none", null, "unset"]}}>Dribbble</span>
              </Link>
            </Flex>
          </Flex>
          <Flex sx={{flexDirection: 'column', gridColumn: ['1/-1', 'span 1']}}>
            <Text variant='h3' sx={{mb: 3}}>Experience</Text>
            <Text sx={{mb: 1}}>Product Designer<span sx={{color: 'muted'}}>, Specle, 2017…19</span></Text>
            <Text sx={{mb: 1}}>Lead Product Designer<span sx={{color: 'muted'}}>, KUPIVIP, 2016…17</span></Text>
            <Text sx={{mb: 1}}>Product Designer<span sx={{color: 'muted'}}>, Semibold Mammoth, 2011…16</span></Text>
            <Text>Product Designer<span sx={{color: 'muted'}}>, Wunderman, 2011…13</span></Text>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'start', gridRow: "span 2", gridColumn: ['1/-1', 'span 1']}}>
            <Text variant='h3' sx={{mb: 3}}>Awards and mentions</Text>
            <Text sx={{mb: 1}}><Link blank href='https://apps.apple.com/ru/story/id1441976102?l=en'>App Store</Link><span sx={{color: 'muted'}}>, Featured by Apple, 2018</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://apps.apple.com/ru/story/id1291362310?l=en'>App Store</Link><span sx={{color: 'muted'}}>, Featured by Apple, 2018</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://apps.apple.com/ru/story/id1320163297?l=en'>App Store</Link><span sx={{color: 'muted'}}>, Featured by Apple, 2017</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://apps.apple.com/ru/story/id1279036881?l=en'>App Store</Link><span sx={{color: 'muted'}}>, Featured by Apple, 2017</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://web.archive.org/web/20161216052916/https://www.imore.com/best-music-controller-mac'>iMore</Link><span sx={{color: 'muted'}}>, Best Music Controller, 2015</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://www.producthunt.com/posts/simplify-for-mac'>Golden Kitty Award</Link><span sx={{color: 'muted'}}>, Nomenee, 2015</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://www.producthunt.com/posts/simplify-for-mac'>Product Hunt</Link><span sx={{color: 'muted'}}>, #2 Product of the Day, 2015</span></Text>
            <Text sx={{mb: 1}}><Link blank href='https://www.fastcompany.com/3032247/megafaces-pavilion-lands-russia-its-first-ever-grand-prix-at-cannes'>Cannes Lions</Link><span sx={{color: 'muted'}}>, Winner, 2014</span></Text>
          </Flex>
          <Flex sx={{flexDirection: 'column', alignItems: 'start', gridColumn: ['1/-1', 'span 1'], "a": {mb: 1}}}>
            <Text variant='h3' sx={{mb: 3}}>Press</Text>
            <Link blank href='https://www.macstories.net/reviews/simplify-a-spotify-music-controller-and-artwork-widget-for-mac/'>MacStories</Link>
            <Link blank href='https://lifehacker.com/simplify-is-an-all-in-one-desktop-controller-for-itunes-5881452'>LifeHacker</Link>
          </Flex>
        </Grid>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
